<template>
  <Dialog v-model:visible="displayModal" :breakpoints="{ '960px': '95vw' }" :closable="false"
          style="height:340px;width: 45%;background:#FFFFFF 0% 0% no-repeat padding-box;border-radius:30px;"
          :modal="true" class="modal-publications">
    <form action="">
      <div>
        <div class="heaeder-img-modal">
          <img class="w-6" src="@/assets/images/icons/modal-createpub-icon.png" />
        </div>
        <span class="text-base header-modal"><strong class="pr-2">Asignar Cod. Barras:</strong><strong class="text-red-800">{{model.BarCode}}</strong></span>
      </div>
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-2" style="display: flex;justify-content: center;">
            <div class="w-full">
              <Dropdown
                optionLabel="nombreCompleto"
                optionValue="ItemCode"
                :options="allMds"
                v-model="model.ItemCode"
                placeholder="Escriba 4 letras para empezar a buscar un medicamento..."
                class="w-full"
                :filter="true"
              />
            </div>
      </div>
    </form>
    <template #footer class="border-modal-footer">
      <div style="display: flex;justify-content: center;">
        <Button label="Cancelar" @click="closeModal" class="p-button-secondary btn-footer-modal-cancel" />
        <Button label="Asignar" @click="onSubmit" class="p-button-danger btn-footer-modal-delete"
                autofocus />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref, reactive } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import Swal from 'sweetalert2'
export default {
  name: 'asignarCodigoBarras',
  emits: ['refrescar'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const allMds = ref([])
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const model = reactive({
      BarCode: null,
      ItemCode: null
    })
    const openModalCodeBars = (payload, mapeo) => {
      model.BarCode = payload
      // allMds.value = []
      allMds.value = mapeo
      displayModal.value = true
    }
    const closeModal = () => {
      displayModal.value = false
      allMds.value = []
    }
    const onSubmit = () => {
      const find = allMds.value.find((a) => a.ItemCode === model.ItemCode)
      Swal.fire({
        icon: 'question',
        title: 'Información',
        html:
          `¿Desea asignar el código de barras:  <strong>${model.BarCode}</strong> para el médicamento: <strong>${find.nombreCompleto}</strong><br>
            ¿Desea asociarlo a un medicamento?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, asignar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          _RecepcionPedidosService.value.updateBarCode(model).then(({ data }) => {
            if (data.status) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error en la asignación de este código de barras al medicamento',
                showConfirmButton: true
              })
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Se asignó el código de barras al medicamento correctamente.',
                showConfirmButton: true
              }).then((response) => {
                if (response.isConfirmed) {
                  emit('refrescar', model)
                  displayModal.value = false
                }
              })
            }
          })
        }
      })
    }
    // const getAllMX = ({ value }) => {
    //   if (value.length >= 4) {
    //     _RecepcionPedidosService.value.getAllMds(value).then(({ data }) => {
    //       allMds.value = data
    //     })
    //   }
    // }
    return {
      displayModal,
      openModalCodeBars,
      closeModal,
      onSubmit,
      // getAllMX,
      allMds,
      model
    }
  }
}
</script>

<style scoped>

</style>
